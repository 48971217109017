
"use strict";
/*======== Doucument Ready Function =========*/
jQuery(document).ready(function () {

  //CACHE JQUERY OBJECTS
  var $window = $(window);

  $window.on('load', function () {

    // $("#status").fadeOut();
    // $("#preloader").delay(350).fadeOut("slow");
    $("body").delay(350).css({ "overflow": "visible" });
    
    /*========== End Masonry Grid ==========*/

    /* Preloader */

    // $("#status").fadeOut();
    // $("#preloader").delay(350).fadeOut("slow");

    /* END of Preloader */

  });
  /*======= jQuery navbar on scroll =========*/


  $window.on('scroll', function () {

    if ($(".navbar-default").add(".navbar-inverse").offset().top > 50) {
      $(".reveal-menu-home").addClass("sticky-nav");
      $(".reveal-menu-blog").addClass("sticky-nav-white");
    } else {
      $(".reveal-menu-home").removeClass("sticky-nav");
      $(".reveal-menu-blog").removeClass("sticky-nav-white");
    }
  });

  /*======= Banner Resize with window size =========*/

  $window.on('resize', function () {
    var bodyheight = $(this).height();
    $("#mt_banner").height(bodyheight);
  }).resize();

  // back to top


  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 50) {
      $('#back-to-top').fadeIn();
    } else {
      $('#back-to-top').fadeOut();
    }
  });

  // scroll body to 0px on click
  $('#back-to-top').on('click', function () {
    $('#back-to-top').tooltip('hide');
    $('body,html').animate({
      scrollTop: 0
    }, 800);
    return false;
  });

  /*======== Isotope Filter Script =========*/

  var mt_personal = window.mt_personal || {},
    $win = $(window);

  mt_personal.Isotope = function () {

    // 4 column layout
    var isotopeContainer = $(".isotopeContainer");
    if (!isotopeContainer.length || !jQuery().isotope) return;
    $win.on('load', function () {
      isotopeContainer.isotope({
        itemSelector: ".isotopeSelector"
      });
      $(".mt_filter").on("click", "a", function (e) {
        $(".mt_filter ul li").find(".active").removeClass("active");
        $(this).addClass("active");
        var filterValue = $(this).attr("data-filter");
        isotopeContainer.isotope({ filter: filterValue });
        e.preventDefault();
      });
    });

  };

  mt_personal.Isotope();

}(jQuery));


$(function () {
  $('a[href="#search"]').on('click', function (event) {
    event.preventDefault();
    $('#search').addClass('open');
    $('#search > form > input[type="search"]').focus();
  });

  $('#search, #search button.close').on('click keyup', function (event) {
    if (event.target == this || event.target.className == 'close' || event.keyCode == 27) {
      $(this).removeClass('open');
    }
  });


  //Do not include! This prevents the form from submitting for DEMO purposes only!
  $('form').submit(function (event) {
    event.preventDefault();
    return false;
  })
});
